<div class="menu"
     [class.dark]="darkMode">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between menu-button-wrapper">
        <div class="d-flex align-items-center">
          <div class="menu-button"
               [routerLink]="'/landing'"
               [class.active]="router.url.includes( 'landing' )">
            <span>home</span>
          </div>

          <div class="menu-button"
               [matMenuTriggerFor]="chapters">
            <span>chapters</span>
          </div>
          <ng-container *ngIf="router.url.includes('chapter')">
            <span class="chapter-count">{{ router.url.split('chapter')[1] }}</span>
          </ng-container>

          <div class="menu-button"
               [routerLink]="'/resources'"
               [class.active]="router.url.includes( 'resources' )">
            <span>resources</span>
          </div>

          <div class="menu-button"
               id="editor-button"
               [routerLink]="'/editor'"
               [class.active]="router.url.includes( 'editor' )">
            <span>editor</span>
          </div>

          <mat-menu #chapters='matMenu'
                    [class]="darkMode ? 'dark' : ''">
            <div *ngFor="let page of pages; index as i">
              <div *ngIf="page?.uid?.includes('chapter')"
                   mat-menu-item
                   routerLink="/{{page?.uid}}">
                <span>chapter {{i+1}}</span>
                <button (click)="openTab($event, page.uid)"
                        class="icon-text-button"
                        [class.dark]="darkMode">
                  <fa-icon [icon]="faLink" size="xs"></fa-icon>
                </button>
              </div>
            </div>
          </mat-menu>
        </div>

        <div class="d-flex align-items-center">
          <button [matMenuTriggerFor]="settingsMenu"
                  class="icon-text-button"
                  [class.dark]="darkMode">
            <span id="settings-text">settings</span>
            <fa-icon [icon]="faCog"></fa-icon>
          </button>


          <mat-menu #settingsMenu='matMenu'
                    [class]="darkMode ? 'dark' : ''">
            <ng-container *ngFor="let setting of settingsLength; index as i">
              <ng-container [ngSwitch]="setting">
                <div *ngSwitchCase="'darkMode'"
                     mat-menu-item
                     (click)="$event.stopPropagation(); settingsService.toggleDarkmode()">
                  <mat-slide-toggle [checked]="settings.darkMode" class="pointer-events-none">Darkmode</mat-slide-toggle>
                </div>
                <div *ngSwitchCase="'ligatures'"
                     mat-menu-item
                     (click)="$event.stopPropagation(); settingsService.toggleLigatures()">
                  <mat-slide-toggle [checked]="settings.ligatures" class="pointer-events-none">Ligatures</mat-slide-toggle>
                </div>
              </ng-container>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

</div>
