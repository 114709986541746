<div class="container" id="collapsibles" [class.dark]="darkmode">
  <div class="row">
    <div class="col-12 col-md-7">
      <h2 [innerHTML]="sectionData.primary.header| prismicDOMtext | code"></h2>
      <p [innerHTML]="sectionData.primary.description | prismicDOM"></p>
    </div>
    <div class="col-12 col-md-5"></div>
  </div>
  <div class="row">
    <div class="col-12 col-md-7">
      <mat-accordion>

        <mat-expansion-panel *ngFor="let collapsible of sectionData.items" [class.dark]="darkmode">
          <mat-expansion-panel-header>
            <mat-panel-title [innerHTML]="collapsible.collapsible_header | prismicDOMtext"
                             [class.dark]="darkmode"></mat-panel-title>
            <mat-panel-description [innerHTML]="collapsible.collapsible_subheader | prismicDOMtext | code"></mat-panel-description>
          </mat-expansion-panel-header>

          <div [innerHTML]="collapsible.collapsible_description | prismicDOM"></div>
          <button class="clickable"
                  *ngIf="collapsible.collapsible_button_text[0] != undefined && collapsible.collapsible_button_link.uid != undefined"
                  routerLink="/{{collapsible.collapsible_button_link.uid}}"
                  [innerHTML]="collapsible.collapsible_button_text[0].text"
                  [class.dark] = darkmode></button>
          <button disabled
                  *ngIf="collapsible.collapsible_button_link.uid == undefined"
                  [class.dark]="darkmode">Coming soon!</button>
        </mat-expansion-panel>

      </mat-accordion>
    </div>
    <div class="col-12 col-md-5"></div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="button incentivize"
              [routerLink]="'/' + sectionData.items[0].collapsible_button_link.uid"
              [class.dark]="darkmode">Start learning!</button>
    </div>
  </div>
</div>
