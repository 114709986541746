<div class="slice" id="header-slice">
  <div class="background-img" [style.backgroundImage]="'url(' + backgroundImg + ')'">
    <div class="shader"></div>
  </div>
  <div class="abs-center d-flex flow-column align-items-center">
    <div class="d-flex flow-column justify-content-center width-content light-shadow">
      <h1 [innerHTML]="sectionData.primary.header[0].text.toUpperCase()" class="white-text shadow-text text-center"></h1>
      <p *ngIf="sectionData.primary.description != undefined"
         [innerHTML]="sectionData.primary.description | prismicDOM"
         class="white-text shadow-text text-center"></p>
      <button routerLink="/chapter1"
              class="outline">START LEARNING NOW</button>
    </div>
  </div>
</div>
