<section id="cookie-notice" [class.dark]="darkMode">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex">
        <fa-icon [icon]="cookie" class="large"></fa-icon>
        <p>
          This site uses <b>no cookies</b> anymore!<br>
          However, <b>indexedDB</b> is now being used to optimize requests and general behavior.<br>
          <a href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API" target="_blank">Find out more</a>
        </p>
        <button mat-icon-button class="mat-button" (click)="click()"><fa-icon [icon]="times"></fa-icon></button>
      </div>
    </div>
  </div>
</section>
