export default {

    "--":1,
    "++":1,
    "!":3,

    "debugger":4,
    "sin":4,
    "cos":4,
    "tan":4,
    "asin":4,
    "acos":4,
    "atan":4,
    "abs":4,
    "sqrt":4,

    "^":5,
    "*":6,
    "/":6,
    "%":6,
    "+":7,
    "-":7,

    ">":8,
    "<":8,
    ">=":8,
    "<=":8,
    "!=":9,
    "==":9,

    "or":10,
    "and":11,
   

    "=":12,
    "*=":12,
    "/=":12,
    "%=":12,
    "+=":12,
    "-=":12
}