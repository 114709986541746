export default [

    "debugger", 

    "abs", 
    "sqrt", 

    "sin", 
    "cos",
    "tan",
    "asin", 
    "acos", 
    "atan", 

    "goto",

    "and",
    "or",  

    "if", 
    "then",
    "else", 
    "end"
]
