<div id="page" [class.dark]="darkMode" [ngClass]="{ 'dark': darkMode, 'no-ligatures': !ligatures }">
    <div *ngIf="page">

      <!-- Handling routes that have no specified endpoint -->
      <div *ngIf="page?.type == 404">
        <h1>404</h1>
      </div>

      <div *ngFor="let section of page?.data?.body">
        <div [ngSwitch]="section.slice_type">
          <app-landing-hero *ngSwitchCase="'landing-hero'" [sectionData]="section"></app-landing-hero>
          <app-header-slice *ngSwitchCase="'header-slice'" [sectionData]="section"></app-header-slice>
        </div>
      </div>

      <!-- Responsible for displaying sections other than header -->
      <div *ngIf="page?.type != 404" class="content-wrapper">

        <app-menu></app-menu>

        <div *ngFor="let section of page?.data?.body">
          <div [ngSwitch]="section.slice_type">
            <app-editor *ngSwitchCase="'editor'" [sectionData]="section"></app-editor>
            <app-standalone-editor *ngSwitchCase="'standalone-editor'"></app-standalone-editor>
            <app-header-card *ngSwitchCase="'header-card'" [sectionData]="section"></app-header-card>
            <app-collapsibles *ngSwitchCase="'collapsibles'" [sectionData]="section"></app-collapsibles>
            <typo-quote *ngSwitchCase="'quote'" [sectionData]="section"></typo-quote>
            <app-text *ngSwitchCase="'text'" [sectionData]="section"></app-text>
            <app-picture *ngSwitchCase="'picture'" [sectionData]="section"></app-picture>
            <app-page-buttons *ngSwitchCase="'page-buttons'" [sectionData]="section"></app-page-buttons>
          </div>
        </div>

        <div *ngIf="router.url.includes( 'resources' )">
          <app-resources></app-resources>
        </div>

        <app-footer *ngIf="!router.url.includes( 'editor' )"></app-footer>
        <app-scroll-top-button></app-scroll-top-button>
      </div>

    </div>
  <app-cookie-notice></app-cookie-notice>
</div>

