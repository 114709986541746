<section class="footer" [class.dark]="darkmode">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <p>Made with <fa-icon [icon]="heart"></fa-icon> and Pepsi by
          <a href="https://pascalstockert.github.io/site" target="_blank">Pascal Stockert</a>
        </p>
        <br>
        <p>Learn more about the <a [routerLink]="'/privacy-policy'">Privacy Policy</a> of this page</p>
      </div>
      <br class="d-sm-block d-md-none">
      <div class="col-12 col-md-6">
        <p>"Starbase" screenshots/images ©️ <a href="https://www.frozenbyte.com/">Frozenbyte Oy</a>, used with permission. "Starbase" is a trademark or registered
          trademark of Frozenbyte Oy in the U.S. and other countries. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</section>
